<template>
  <div>
    <div class="container mt-5 pt-5">
      <div class="row mt-1">
        <div class="col-md-3 d-none d-sm-none d-md-block">
          <router-link to="/productos"
            ><mdb-card class="mi-card-todos mi-card-3 mb-2 hoverable">
              <mdb-card-body class="text-center pt-4 mt-3">
              </mdb-card-body>
            </mdb-card></router-link
          >
          <router-link to="/producto/21"
            ><mdb-card class="mi-card-mallaciclonica mi-card-3 mb-2 hoverable">
              <mdb-card-body class="text-center pt-4 mt-3">
                <h5><strong>Malla ciclonica</strong></h5>
              </mdb-card-body>
            </mdb-card></router-link
          >
          <router-link to="/producto/28"
            ><mdb-card class="mi-card-reja mi-card-3 mb-2 hoverable">
              <mdb-card-body class="text-center pt-4 mt-3">
                <h5><strong>Rejas de acero</strong></h5>
              </mdb-card-body>
            </mdb-card></router-link
          >
          <router-link to="/producto/40"
            ><mdb-card
              class="mi-card-cercaselectricas mi-card-3 mb-2 hoverable"
            >
              <mdb-card-body class="text-center pt-4 mt-3">
                <h5><strong>Cercas electricas</strong></h5>
              </mdb-card-body>
            </mdb-card></router-link
          >
          <router-link to="/producto/13"
            ><mdb-card class="mi-card-concertina mi-card-3 mb-2 hoverable">
              <mdb-card-body class="text-center pt-4 mt-3">
                <h5><strong>Concertina</strong></h5>
              </mdb-card-body>
            </mdb-card></router-link
          >
          <router-link to="/productos/Otras Mallas"
              ><mdb-card class="mi-card-e1 mi-card-3 mb-2 hoverable">
                <mdb-card-body class="text-center pt-0 mt-3">
                  <h5 class="pb-0 mb-0"><strong>Triple torsión</strong></h5>
                  <h5 class="my-0 py-0"><strong>Hexagonal</strong></h5>
                  <h5 class="mt-0 pt-0"><strong>Gaviones</strong></h5>
                </mdb-card-body>
              </mdb-card></router-link
            >
            <router-link to="/productos/Sombra y Mosquitera"
              ><mdb-card class="mi-card-e3 mi-card-3 mb-2 hoverable">
                <mdb-card-body class="text-center pt-3 mt-3">
                  <h5><strong>Malla sombra y Malla mosquitera</strong></h5>
                </mdb-card-body>
              </mdb-card></router-link
            >
            <router-link to="/productos/Perfil y Zig Zag"
              ><mdb-card class="mi-card-e4 mi-card-3 mb-2 hoverable">
                <mdb-card-body class="text-center pt-3 mt-3">
                  <h5><strong>Perfil sujetador y Alambre Zig Zag</strong></h5>
                </mdb-card-body>
              </mdb-card></router-link
            >
            <router-link to="/productos/Mallas para Animales"
              ><mdb-card class="mi-card-e5 mi-card-3 mb-2 hoverable">
                <mdb-card-body class="text-center pt-0 mt-3">
                  <h5 class="my-0 py-0"><strong>Malla borreguera</strong></h5>
                  <h5 class="my-0 py-0"><strong>Multimalla</strong></h5>
                  <h5 class="my-0 py-0"><strong>Criba</strong></h5>
                </mdb-card-body>
              </mdb-card></router-link
            >
            <router-link to="/productos/Metal y Laminas"
              ><mdb-card class="mi-card-e7 mi-card-3 mb-2 hoverable">
                <mdb-card-body class="text-center pt-0 mt-3">
                  <h5 class="my-0 py-0"><strong>Metal deplegado</strong></h5>
                  <h5 class="my-0 py-0"><strong>Lamina perforada</strong></h5>
                  <h5 class="my-0 py-0"><strong>Láminas</strong></h5>
                </mdb-card-body>
              </mdb-card></router-link
            >
            <router-link to="/productos/Tuberia"
              ><mdb-card class="mi-card-tuberia mi-card-3 mb-2 hoverable">
                <mdb-card-body class="text-center pt-4 mt-3">
                  <h5><strong>Tuberia para cercas</strong></h5>
                </mdb-card-body>
              </mdb-card></router-link
            >
            <router-link to="/productos/Tubo Galvanizado"
              ><mdb-card class="mi-card-tubo mi-card-3 mb-2 hoverable">
                <mdb-card-body class="text-center pt-3 mt-3">
                  <h5><strong>Tubo galvanizado y PTR galvanizado</strong></h5>
                </mdb-card-body>
              </mdb-card></router-link
            >
          <a
            target="_blank"
            href="https://www.sistemasdeseguridadyproteccion.com/"
            ><mdb-card class="mi-card-ss mi-card-3 mb-2 hoverable">
              <mdb-card-body class="text-center pt-4 mt-3">
                <h5><strong>Sistemas de seguridad</strong></h5>
              </mdb-card-body>
            </mdb-card></a
          >
          <router-link to="/producto/36"
            ><mdb-card class="mi-card-mallacinta mi-card-3 mb-2 hoverable">
              <mdb-card-body class="text-center pt-4 mt-3">
                <h5><strong>Cinta plastica</strong></h5>
              </mdb-card-body>
            </mdb-card></router-link
          >
          <router-link to="/producto/45"
              ><mdb-card class="mi-card-e6 mi-card-3 mb-2 hoverable">
                <mdb-card-body class="text-center pt-4 mt-3">
                  <h5><strong>Cerca Tubular</strong></h5>
                </mdb-card-body>
              </mdb-card></router-link
            >
          <router-link to="/producto/35"
            ><mdb-card class="mi-card-mallasombra mi-card-3 mb-2 hoverable">
              <mdb-card-body class="text-center pt-4 mt-3">
                <h5><strong>Malla sombra</strong></h5>
              </mdb-card-body>
            </mdb-card></router-link
          >
          <router-link to="/producto/23"
            ><mdb-card class="mi-card-mallamosquitera mi-card-3 mb-2 hoverable">
              <mdb-card-body class="text-center pt-4 mt-3">
                <h5><strong>Malla mosquitera</strong></h5>
              </mdb-card-body>
            </mdb-card></router-link
          >
          <router-link to="/producto/20"
            ><mdb-card class="mi-card-mallaborreguera mi-card-3 mb-2 hoverable">
              <mdb-card-body class="text-center pt-4 mt-3">
                <h5><strong>Malla borreguera</strong></h5>
              </mdb-card-body>
            </mdb-card></router-link
          >
          <router-link to="/productos/Alambre"
            ><mdb-card class="mi-card-alambre mi-card-3 mb-2 hoverable">
              <mdb-card-body class="text-center pt-4 mt-3">
                <h5><strong>Alambres</strong></h5>
              </mdb-card-body>
            </mdb-card></router-link
          >
          <router-link to="/producto/25"
            ><mdb-card class="mi-card-metal mi-card-3 mb-2 hoverable">
              <mdb-card-body class="text-center pt-4 mt-3">
                <h5><strong>Metal desplegado</strong></h5>
              </mdb-card-body>
            </mdb-card></router-link
          >
          <router-link to="/producto/19"
            ><mdb-card class="mi-card-laminas mi-card-3 mb-2 hoverable">
              <mdb-card-body class="text-center pt-4 mt-3">
                <h5><strong>Láminas</strong></h5>
              </mdb-card-body>
            </mdb-card></router-link
          >
          <router-link to="/productos/Tuberia"
            ><mdb-card class="mi-card-tuberia mi-card-3 mb-2 hoverable">
              <mdb-card-body class="text-center pt-4 mt-3">
                <h5><strong>Tuberia para cercas</strong></h5>
              </mdb-card-body>
            </mdb-card></router-link
          >
          <router-link to="/productos/Accesorios para cercas"
            ><mdb-card class="mi-card-accesorios mi-card-3 mb-2 hoverable">
              <mdb-card-body class="text-center pt-4 mt-3">
                <h5><strong>Accesorios para cercas</strong></h5>
              </mdb-card-body>
            </mdb-card></router-link
          >
          <router-link to="/producto/22"
            ><mdb-card class="mi-card-mallahexagonal mi-card-3 mb-2 hoverable">
              <mdb-card-body class="text-center pt-4 mt-3">
                <h5><strong>Malla hexagonal</strong></h5>
              </mdb-card-body>
            </mdb-card></router-link
          >
          <router-link to="/producto/26"
            ><mdb-card class="mi-card-multimalla mi-card-3 mb-2 hoverable">
              <mdb-card-body class="text-center pt-4 mt-3">
                <h5><strong>Multimalla</strong></h5>
              </mdb-card-body>
            </mdb-card></router-link
          >
          <router-link to="/follaje"
            ><mdb-card class="mi-card-follaje mi-card-3 mb-2 hoverable">
              <mdb-card-body class="text-center pt-4 mt-3">
                <h5><strong>Follaje artificial</strong></h5>
              </mdb-card-body>
            </mdb-card></router-link
          >
          <a target="_blank" href="https://www.desarrollointegralcanino.com/"
            ><mdb-card class="mi-card-seguridadcanina mi-card-3 mb-2 hoverable">
              <mdb-card-body class="text-center pt-4 mt-3">
                <h5><strong>Seguridad canina</strong></h5>
              </mdb-card-body>
            </mdb-card></a
          >
          <router-link to="/maderaplas"
            ><mdb-card class="mi-card-madera mi-card-3 mb-2 hoverable">
              <mdb-card-body class="text-center pt-4 mt-3">
                <h5><strong>Maderaplas</strong></h5>
              </mdb-card-body>
            </mdb-card></router-link
          >
        </div>
        <div class="col-md-9">
          <div class="dark-font">
            <mdb-breadcrumb>
              <mdb-breadcrumb-item
                ><router-link to="/productos" class="red-text"
                  >Productos</router-link
                ></mdb-breadcrumb-item
              >
              <mdb-breadcrumb-item
                ><router-link
                  :to="`/productos/${product.category}`"
                  class="red-text"
                  >{{ product.category }}</router-link
                ></mdb-breadcrumb-item
              >
              <mdb-breadcrumb-item active>{{
                product.title
              }}</mdb-breadcrumb-item>
            </mdb-breadcrumb>
          </div>
          <div class="row mt-3">
            <div class="col-md-6 text-center">
              <img
                v-if="product.image"
                data-aos="fade-in"
                :src="require(`../assets/products/${product.image}`)"
                class="img-fluid"
                alt=""
                style="max-height: 440px"
              />
            </div>
            <div class="col-md-6">
              <h2 class="mb-4 mt-3">
                <strong>{{ product.title }}</strong>
              </h2>
              <p>
                {{ product.description }}
              </p>
              <div class="text-center">
                <a
                  target="_blank"
                  :href="`https://api.whatsapp.com/send?phone=5212293060014&text=Quiero%20hacer%20una%20cotizaci%C3%B3n%20de%20${product.title}%20`"
                  ><mdb-btn color="success" rounded size="lg"
                    >Cotizar<mdb-icon
                      fab
                      icon="whatsapp"
                      class="ml-2" /></mdb-btn
                ></a>
                <a
                  target="_blank"
                  v-if="product.title == 'Cercas electricas'"
                  href="https://www.sistemasdeseguridadyproteccion.com/"
                  ><mdb-btn color="indigo darken-4" size="lg"
                    >Ver más</mdb-btn
                  ></a
                >
              </div>
            </div>
          </div>
          <div>
            <images
              class="mt-4"
              :category="product.category"
              :product="product.title"
            />
          </div>
          <div class="pb-5">
            <mdb-card class="card-body mt-5 my-red white-text text-white">
            <div v-if="product.specs">
              <h3 class="mt-4">Especificaciones:</h3>
              <hr />
              <mdb-card-text class="mt-4">{{ product.specs }}</mdb-card-text>
            </div>
            <div v-if="product.aplicaciones">
              <h3 class="mt-4">Aplicaciones:</h3>
              <hr />
              <mdb-card-text class="mt-4">{{
                product.aplicaciones
              }}</mdb-card-text>
            </div>
            <div v-if="product.advantages">
              <h3 class="mt-4">Ventajas:</h3>
              <hr />
              <mdb-card-text class="mt-4">{{
                product.advantages
              }}</mdb-card-text>
            </div>
            <div v-if="product.medidas">
              <h3 class="mt-4">Medidas:</h3>
              <hr />
              <img
                :src="require(`../assets/products/${product.medidas}`)"
                class="img-fluid mb-4"
                alt="medidas"
              />
            </div>
            <div class="mt-5">
              <p>
                Instalación de {{ product.title }} en Acapulco, Guerrero, Ixtapa Zihuatanejo, Chilpancingo, Iguala, México, Querétaro, Toluca, Ciudad de México, Morelos, Cuernavaca, Puebla, Tehuacan, Tulancingo, Pachuca, Tlaxcala, Veracruz, Xalapa, Poza Rica, Huatusco, Córdoba, Orizaba, Coatzacoalcos, Tuxpan, Acayucan y el resto de la República Mexicana.
              </p>
            </div>
          </mdb-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mdbBtn,
  mdbCard,
  mdbCardBody,
  mdbCardText,
  mdbIcon,
  mdbBreadcrumb,
  mdbBreadcrumbItem,
} from "mdbvue";
import images from "../components/imagenes";

export default {
  components: {
    mdbBtn,
    mdbCard,
    mdbCardBody,
    mdbCardText,
    mdbIcon,
    mdbBreadcrumb,
    mdbBreadcrumbItem,
    images,
  },
  data() {
    return {
      fourRandom: [],
      id: this.$route.params.id,
      product: {},
      allImages: [
        "1.jpg",
        "2.jpg",
        "3.jpg",
        "4.jpg",
        "5.jpg",
        "6.jpg",
        "7.jpg",
        "8.jpg",
        "9.jpg",
        "10.jpg",
        "11.jpg",
        "12.jpg",
        "13.jpg",
        "16.jpg",
      ],
      images: [],
    };
  },
  props: {
    items: Array,
    prod: Number,
  },
  mounted() {
    this.getProduct();
    this.setTitle();
  },
  updated() {
    this.setTitle();
  },
  watch: {
    $route(to) {
      this.id = to.params.id;
      this.getProduct();
    },
  },
  methods: {
    getProduct() {
      this.items.forEach((item) => {
        if (item.id == this.id || this.prod) {
          this.product = item;
        }
      });
    },
    setTitle() {
      document.title = `Cercas y Mallas Versatiles - ${this.product.title}`;
    },
  },
};
</script>

<style>
.my-pt-3 {
  margin-top: 40px;
}

/* cards */
.mi-card-todos {
  background: url("../assets/Categorias/todos.jpeg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-accesorios {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/accesorios.jpg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-alambre {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/alambre.jpg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-concertina {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/concertina.png") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-laminas {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/laminas.jpg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-mallaciclonica {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/cercas2.jpeg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-metal {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/metaldesplegado.jpg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-reja {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/rejadeacero.jpg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-tubo {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/8.jpeg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-tuberia {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/2.jpeg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-mallaborreguera {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/mallaborreguera.jpg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-mallacinta {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/mallaciclonicaconcintaplastica.jpg") center
      no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-mallahexagonal {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/mallahexagonal.jpg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-mallamosquitera {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/mallamosquitera.jpg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-mallasombra {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/mallasombra.jpg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-multimalla {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/multimalla.jpg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-seguridadcanina {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/seguridadcanina.jpg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-cercaselectricas {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/cercaselectricas.jpg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-ss {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/circuito.jpg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-follaje {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/follaje/follaje/10.jpg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-madera {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/madera/madera005.jpg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-e1 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/1.jpeg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-e3 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/3.jpeg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-e4 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/4.jpeg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-e5 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/5.jpeg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-e6 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/6.jpeg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-e7 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Categorias/7.jpeg") center no-repeat;
  background-size: cover;
  height: 100px;
  color: #e8eaf6;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
  vertical-align: middle;
}
.mi-card-3:hover,
.mi-card-3:focus,
.mi-card-3:active {
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>